import React from "react"
import { Link } from "gatsby"
import HighlightedLink from "../HighlightedLink"
import { Container } from "./styled"

const Nav = ({ side = false }) => {
  const items = [
    {
      id: "about",
      name: "About Us",
    },
    {
      id: "how",
      name: "How It Works",
    },
    {
      id: "faqs",
      name: "FAQs",
    },
    {
      id: "filters",
      name: "Buy Filters",
    },
  ]

  return (
    <Container className={side ? "side" : ""}>
      <ul>
        {items.map(item => {
          return (
            <li key={item.id}>
              <Link to={`/${item.id}`}>{item.name}</Link>
            </li>
          )
        })}
      </ul>
      <ul>
        <li>
          <HighlightedLink to="/buy/1" name="Get Started" />
        </li>
      </ul>
    </Container>
  )
}

export default Nav
