import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  position: relative;

  & > div {
    margin: 0 10px;
  }

  & > div:first-child {
    margin-left: 0;
  }

  & > div:last-child {
    margin-right: 0;
  }

  & > div {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #fff;
    text-transform: uppercase;
    font-family: "Muli", sans-serif;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
  }

  & > div a,
  & > div a:visited {
    display: block;
    padding: 15px 30px;
    background-color: var(--green);
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    transition: background 0.5s ease, color 0.5s ease;
  }

  & > div a:hover,
  & > div a:focus {
    background-color: var(--lightgreen);
  }

  & > div.invert a,
  & > div.invert a:visited {
    background-color: #fff;
    color: var(--green);
  }

  & > div.invert a:hover,
  & > div.invert a:focus {
    background-color: var(--lightgreen);
    color: #fff;
  }

  & > div.blue a,
  & > div.blue a:visited {
    background-color: var(--darkblue);
    color: #fff;
  }

  & > div.blue a:hover,
  & > div.blue a:focus {
    background-color: var(--blue);
    color: #fff;
  }
`
