import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-gtag"
import logo from "../../img/logo-mechanical-ac-ko.png"
import { Section, Container, FlexContainer } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faCommentsAlt, faEnvelope } from "@fortawesome/pro-solid-svg-icons"

const Footer = () => {
  return (
    <Section>
      <div>
        <Container className="main-page">
          <FlexContainer>
            <div className="address">
              <div>
                8050 Monetary Drive
                <br />
                Riviera Beach, FL 33404
                <br />
                Phone: 561-848-6227
              </div>
              <div className="social">
                <div>
                  <OutboundLink
                    href="https://www.facebook.com/MechanicalAirConditioning/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                    <span className="text-hide">Facebook Icon</span>
                  </OutboundLink>
                </div>
                <div>
                  <OutboundLink
                    href="https://twitter.com/MechanicalAC"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                    <span className="text-hide">Twitter Icon</span>
                  </OutboundLink>
                </div>

                <div>
                  <OutboundLink
                    href="https://www.mechanicalac.com/about/reviews"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faCommentsAlt} />
                    <span className="text-hide">Comments Icon</span>
                  </OutboundLink>
                </div>

                <div>
                  <Link to="/contact">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span className="text-hide">Envelope Icon</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="logo">
              <div>
                <Link to="/">
                  <img
                    src={logo}
                    alt="Logo of Mechanical Air Conditioning Corporation"
                    title="Mechanical Air Conditioning"
                  />
                </Link>
              </div>
            </div>
            <div className="links-container">
              <div>Licensed & Certified #CAC1816081</div>
              <div className="links">
                <div>
                  <Link to="/privacy">Privacy Policy</Link>
                </div>
                <div>
                  <Link to="/accessibility">Accessibility Statement</Link>
                </div>
              </div>
            </div>
          </FlexContainer>
          <div>© 2020, Mechanical Air Conditioning. All Rights Reserved.</div>
        </Container>
      </div>
    </Section>
  )
}

export default Footer
