import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import { getCart } from "../../utils/helpers"
import Nav from "../Nav"
import logo from "../../img/logo-mechanical-ac.png"
import { Cart, Navigation, SideNav } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneAlt,
  faCommentsAlt,
  faShoppingCart,
  faBars,
} from "@fortawesome/pro-light-svg-icons"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"

const Header = () => {
  const [open, setOpen] = useState(false)

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <Fragment>
      <header>
        <Cart>
          <div className="cart-container main-page">
            <div>
              <a href="tel:561-848-6227">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="icon-left mobileHide"
                />
                <span className="text-hide">Telephone Icon</span>(561) 848-6227
              </a>
            </div>
            <div>
              <Link to="/contact">
                <FontAwesomeIcon icon={faCommentsAlt} className="icon-left" />
                <span className="text-hide">Text Icon</span>Text Us
              </Link>
            </div>
            <div>
              <Link to="/cart">
                <div className="cart-flex-container">
                  <div>
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      className="icon-left"
                    />
                    <span className="text-hide">Shopping Cart Icon</span>Cart
                  </div>
                  <div className="circle">{getCart().length || 0}</div>
                </div>
              </Link>
            </div>
          </div>
        </Cart>
        <Navigation>
          <div className="navigation-container main-page">
            <div className="logo">
              <Link to="/">
                <img
                  src={logo}
                  alt="Logo of Mechanical Air Conditioning Corporation"
                  title="Mechanical Air Conditioning"
                />
              </Link>
            </div>
            <Nav />
            <div className="mobile-nav">
              <div className="bars" onClick={() => toggle()}>
                <FontAwesomeIcon icon={faBars} aria-hidden="true" />
                <span className="text-hide">Open Navigation</span>
              </div>
            </div>
          </div>
        </Navigation>
      </header>
      <SideNav className={open ? "open" : ""}>
        <div className="closebtn">
          <button onClick={() => toggle()}>
            <FontAwesomeIcon icon={faTimes} aria-hidden="true" />
            <span className="text-hide">Close Navigation</span>
          </button>
        </div>
        <Nav side={true} />
      </SideNav>
    </Fragment>
  )
}

export default Header
