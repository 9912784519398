import styled from "styled-components"

export const Cart = styled.div`
  background-color: #154d7c;
  width: 100%;
  padding: 20px 0;

  @media print {
    display: none;
  }

  & .cart-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;

    @media screen and (max-width: 1023px) {
      justify-content: center;
    }
  }

  & .cart-container > div {
    padding: 0 15px;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
  }

  & .cart-container > div:first-child {
    padding-left: 0;
  }

  & .cart-container > div:last-child {
    padding-right: 0;
  }

  & .cart-container > div a,
  & .cart-container > div a:visited {
    color: #fff;
    text-decoration: none;
    transition: color 0.25s ease-in-out;
  }

  & .cart-container > div a:hover {
    color: #8aa6be;
  }

  & .cart-flex-container {
    display: flex;
    align-items: center;
  }

  & .cart-flex-container > div {
    margin: 0 5px;
  }

  & .cart-flex-container > div:first-child {
    margin-left: 0;
  }

  & .cart-flex-container > div:last-child {
    margin-right: 0;
  }

  & .cart-flex-container > div.circle {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background-color: #2e77b2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-family: "Muli", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #fff;
  }
`

export const Navigation = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 20px 0;

  & .navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .navigation-container > div {
    margin: 0 20px;
  }

  & .navigation-container > div:first-child {
    margin-left: 0;
  }

  & .navigation-container > div:last-child {
    margin-right: 0;
  }

  & .navigation-container > div.logo {
    width: 250px;
    height: auto;
  }

  & .navigation-container .mobile-nav {
    display: none;

    @media screen and (max-width: 1023px) {
      display: block;
    }
  }

  & .navigation-container nav {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  & .navigation-container .mobile-nav .bars {
    font-size: 24px;
    color: #154d7c;
  }

  & .navigation-container .mobile-nav .bars:hover {
    cursor: pointer;
  }
`

export const SideNav = styled.div`
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #154d7c;
  overflow-x: hidden;
  transition: 0.25s;
  padding-top: 75px;
  text-align: center;

  &.open {
    width: 100%;
  }

  & .closebtn {
    position: absolute;
    top: 30px;
    right: 25px;
    font-size: 24px;
    margin-left: 40px;
    color: #fff;
  }

  & .closebtn button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    vertical-align: middle;
    text-align: center;
    font-size: inherit;
    color: #fff;
  }
`
