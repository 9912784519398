import React from "react"
import { Link } from "gatsby"
import { Container } from "./styled"

const HighlightedLink = ({
  to,
  name,
  invert = false,
  blue = false,
  larger = false,
}) => {
  return (
    <Container>
      <div className={invert ? "invert" : blue ? "blue" : ""}>
        <Link to={to}>{name}</Link>
      </div>
    </Container>
  )
}

export default HighlightedLink
