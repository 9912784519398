import styled from "styled-components"

export const Section = styled.footer`
  & > div {
    background-color: #154d7c;
    width: 100%;
    padding: 40px 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  & > div {
    margin: 10px 0;
  }

  & > div:first-child {
    margin-top: 0;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  & > div {
    flex: 1;
    padding: 0 10px;

    @media screen and (max-width: 1023px) {
      padding: 10px 0;
    }
  }

  & > div:first-child {
    padding-left: 0;

    @media screen and (max-width: 1023px) {
      padding-top: 0;
    }
  }

  & > div:last-child {
    padding-right: 0;

    @media screen and (max-width: 1023px) {
      padding-bottom: 0;
    }
  }

  & > div.logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media print {
      display: none;
    }

    @media screen and (max-width: 1023px) {
      order: -1;
      margin-bottom: 10px;
    }
  }

  & > div.logo > div {
    margin: 10px 0;
  }

  & > div.logo > div:first-child {
    margin-top: 0;
  }

  & > div.logo > div:last-child {
    margin-bottom: 0;
  }

  & > div.logo img {
    width: 300px;
    height: auto;

    @media screen and (max-width: 767px) {
      width: 250px;
    }
  }

  & > div.address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 1023px) {
      align-items: center;
      justify-content: center;
    }
  }

  & > div.address > div {
    margin: 5px 0;
  }

  & > div.address > div:first-child {
    margin-top: 0;
  }

  & > div.address > div:last-child {
    margin-bottom: 0;
  }

  & > div.address > div.social {
    display: flex;
    justify-content: flex-end;
  }

  & > div.address > div.social > div {
    margin: 0 3px;
  }

  & > div.address > div.social > div:first-child {
    margin-left: 0;
  }

  & > div.address > div.social > div:last-child {
    margin-right: 0;
  }

  & > div.address > div.social > div {
    text-align: center;
    line-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #154d7c;
    font-size: 20px;
  }

  & > div.address > div.social > div a,
  & > div.address > div.social > div a:visited {
    color: #154d7c;
    display: block;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    background-color: #fff;
    transition: background 0.5s ease;
  }

  & > div.address > div.social > div a:hover {
    background-color: #8aa6be;
  }

  & > div.links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    @media print {
      display: none;
    }

    @media screen and (max-width: 1023px) {
      align-items: center;
      justify-content: center;
    }
  }

  & > div.links-container > div {
    margin: 8px 0;
  }

  & > div.links-container > div:first-child {
    margin-top: 0;
  }

  & > div.links-container > div:last-child {
    margin-bottom: 0;
  }

  & > div.links-container > div.links {
    display: flex;
    justify-content: flex-end;
  }

  & > div.links-container > div.links > div {
    padding: 0 10px;
  }

  & > div.links-container > div.links > div:first-child {
    padding-left: 0;
  }

  & > div.links-container > div.links > div:last-child {
    padding-right: 0;
  }

  & > div.links-container > div.links a,
  & > div.links-container > div.links a:visited {
    color: #fff;
    text-decoration: none;
    transition: color 0.25s ease-in-out;
  }

  & > div.links-container > div.links a:hover {
    color: #8aa6be;
  }
`
