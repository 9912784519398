import styled from "styled-components"

export const Container = styled.nav`
  display: flex;
  align-items: center;

  @media print {
    display: none;
  }

  &.side {
    flex-direction: column;
  }

  & ul {
    display: flex;
    list-style: none;
    padding: 0 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  &.side ul {
    margin: 20px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    font-weight: 300;
  }

  & ul:first-child {
    padding-left: 0;
  }

  &.side ul:first-child {
    margin-top: 0;
  }

  & ul:last-child {
    padding-right: 0;
  }

  &.side ul:last-child {
    margin-bottom: 0;
    padding: 0;
  }

  & ul li {
    padding: 0 10px;
  }

  &.side ul li {
    padding: 0;
    margin: 20px;
    font-size: 24px;
    line-height: 24px;
  }

  & ul li:first-child {
    padding-left: 0;
  }

  &.side ul li:first-child {
    margin-top: 0;
  }

  & ul li:last-child {
    padding-right: 0;
  }

  &.side ul li:last-child {
    margin-bottom: 0;
  }

  & ul:first-child li a,
  & ul:first-child li a:visited {
    color: #154d7c;
    text-decoration: none;
    transition: color 0.25s ease-in-out;
  }

  &.side ul:first-child li a,
  &.side ul:first-child li a:visited {
    color: #fff;
  }

  & ul:first-child li a:hover {
    color: #2e77b2;
  }

  &.side ul:first-child li a:hover {
    color: #fff;
  }

  &.side ul > li .button {
    font-size: 18px;
    line-height: 18px;
  }
`
